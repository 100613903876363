import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../components/shared/Button";
import { useNavigate } from "react-router-dom";
import { generateRandomDots } from "../../utils";

const CampaignLogin = () => {
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
  };
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleLogin = async (values, { resetForm }) => {
    try {
      setIsLoading(true);
      localStorage.setItem("campaignEmail", values.email);
      navigate("/change-password_c");
    } catch (error) {}
    setIsLoading(false);
  };

  const dots = generateRandomDots(50);

  return (
    <div className="relative flex min-h-screen justify-center items-center px-4 py-20 bg-[#f5f6fa]">
      {dots.map((dot) => (
        <span
          key={dot.id}
          className="absolute bg-yellow-500 rounded-full"
          style={{
            width: dot.size,
            height: dot.size,
            top: dot.top,
            left: dot.left,
            opacity: Math.random() * 0.8 + 0.2, // Random opacity between 0.2 - 1
          }}
        ></span>
      ))}

      <div className="space-y-8">
        <div className="text-center">
          <p className="font-bold font-Montserrat text-6xl lg:text-5xl md:text-5xl sm:text-3xl xs:text-3xl bg-gradient-to-r from-yellow-500  via-[#001f68] to-yellow-600 bg-clip-text text-transparent">
            Welcome to
          </p>
          <p className="text-8xl lg:text-6xl md:text-6xl sm:text-4xl xs:text-4xl italic font-semibold text-yellow-600">
            Igcarts
          </p>
        </div>
        <div className="w-full lg:px-4 md:px-3 sm:px-2 xs:px-2 bg-white rounded-lg shadow-md">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            {({ handleSubmit, isValid, setFieldValue }) => (
              <Form
                className="px-8 lg:px-5 md:px-4 sm:px-3 xs:px-2 py-4"
                onSubmit={handleSubmit}
              >
                <p className="text-center text-yellow-600 text-2xl md:text-4xl lg:text-4xl mb-4">
                  Confirm your email to continue
                </p>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-black text-sm font-FiraSans font-bold mb-2"
                  >
                    Email Address
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="user@email.com"
                    className={`shadow appearance-none border font-FiraSans rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${
                      isValid ? "border-gray-300" : "border-red-500"
                    }`}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="email"
                    component="p"
                    className="text-red-500 text-sm italic"
                  />
                </div>

                <div className="flex items-center justify-center mt-6">
                  <Button
                    type="submit"
                    title={isLoading ? "Proceeding..." : "Proceed"}
                    variant={isValid ? "primary" : "disabled"}
                    disabled={!isValid || isLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CampaignLogin;
