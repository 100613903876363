import React, { useEffect, useState } from "react";
import Table from "../../../../../components/shared/Table";
import { formatTimestamp } from "../../../../../utils";
import { ApiService } from "../../../../../services/apiService";

const IpCheck = () => {
  const [ipPostMaker, setIpPostMaker] = useState([]);

  const fetchIpMaker = async () => {
    try {
      const response = await ApiService.getIPMakingPost();
      setIpPostMaker(response.data.data);
    } catch (error) {
      alert("We encountered an error while fetching Ips, please try again");
    }
  };

  useEffect(() => {
    fetchIpMaker();
  }, []);

  const ipMakerDetails = [
    // {
    //   name: "Ord. Id",
    //   selector: (row) => row.auto_order_id,
    //   width: "120px",
    // },
    // {
    //   name: "Sub. Id",
    //   selector: (row) => row.auto_subscription_id,
    //   width: "120px",
    // },
    {
      name: "User",
      selector: (row) => row.user_id,
      // width: "140px",
    },
    {
      name: "Qty.",
      selector: (row) => row.quantity,
      // width: "80px",
    },
    {
      name: "Service",
      selector: (row) => row.service,
      // width: "80px",
    },
    {
      name: "Service Name",
      selector: (row) => row.service_name,
      // width: "110px",
    },
    {
      name: "Time",
      selector: (row) => formatTimestamp(row.createdAt),
      // width: "120px",
    },
    {
      name: "Ip Add.",
      selector: (row) => row.ip_address,
      // width: "130px",
    },
    {
      name: "Link",
      selector: (row) => (
        <a href={row.link} target="_blank" rel="noopener noreferrer">
          {row.link}
        </a>
      ),
      // width: "230px",
    },
    // {
    //   name: "Link",
    //   selector: (row) => (
    //     <a href={row.last_post} target="_blank" rel="noopener noreferrer">
    //       {row.last_post}
    //     </a>
    //   ),
    //   width: "230px",
    // },
  ];
  return (
    <div className="mt-6">
      <Table
        title="Ip Making Post"
        column={ipMakerDetails}
        data={ipPostMaker}
      />
    </div>
  );
};

export default IpCheck;
