import fastDel from "../assets/fastImg.jpg";
import compPricing from "../assets/cheapimg.jpg";
import support from "../assets/supportImg.jpg";
import safety from "../assets/safety_security.png";
import userFri from "../assets/user_friendly.png";
import popular from "../assets/popular_feed.png";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import spotify from "../assets/spotify.png";
import soundcloud from "../assets/soundcloud.png";
// import twitter from "../assets/twitter.png";
import X from "../assets/X.jpeg";
import youtube from "../assets/youtube.png";

const hompageCardRendering = [
  {
    bgColor: "#e27f48",
    heading: "USER FRIENDLY",
    description:
      "Interface is very simple to use. Basically all that is needed to register is a email",
    image: userFri,
  },
  {
    bgColor: "#f5bd3f",
    heading: "24/7",
    description:
      "Live support in the form of chat is available anytime of the day.",
    image: support,
  },
  {
    bgColor: "#3f3776",
    heading: "SUPER FAST DELIVERY",
    description: "We ensure speedy delivery, within or less than a minute.",
    image: fastDel,
  },
  {
    bgColor: "#767572",
    heading: "SAFETY & SECURITY",
    description:
      "Your security is one of our top priorities. We do NOT need your Instagram passwords or any other important personal details. We do NOT employ 3rd party sites.",
    image: safety,
  },
  {
    bgColor: "#2165bd",
    heading: "POPULAR FEED",
    description: "Expect to see your photos and videos on the popular feed!",
    image: popular,
  },
  {
    bgColor: "#8dc66f",
    heading: "COMPETITIVE PRICING",
    description: "Reasonably priced, excellent, top of the line service.",
    image: compPricing,
  },
];

export { hompageCardRendering };

const homepageMedias = [instagram, facebook, youtube, spotify, soundcloud, X];

export { homepageMedias };

const accordionRendering = [
  {
    title: "Q. How to add likes/views to my account?",
    content:
      "A. Click on Ig likes tab or Ig views tab and add your account.\n\nLoad your media and choose the photo/video.\n\nFinally add the amount you want",
  },
  {
    title: "Q. Time of delivery?",
    content: "A. Super-fast-less than 30 -45 seconds !!!",
  },
  {
    title: "Q. Can i add same media likes or views?",
    content: "A. Yes ,sure but please give a gap of 5-10 min",
  },
  {
    title: "Q. What kind of payment is accepted?",
    content: "A. Paypal or Credit Card",
  },
  {
    title: "Q.Is it safe?",
    content: "A. Our process are 100% safe",
  },
  {
    title: "Q. Can my photos be in popular feed?",
    content:
      "A. Yes sure! Because our likes are so fast your post could be in popular feed (just send likes right after posting)",
  },
  {
    title: "Q. Do you need my Instagram password?",
    content:
      "A. NO. we need only your Instagram username (make sure your not in private)",
  },
  {
    title: "Q. Do you provide Instagram followers also?",
    content: "A. Yes sure :)",
  },
  {
    title: "Q. Do you provide Instagram custom comments?",
    content: "A. Yes,you can add yourself your own comment for your posts",
  },
  {
    title: "Q. Do you provide Facebook page/post likes?",
    content: "A. Yes,sure",
  },
  {
    title: "Q. Can i be your reseller?",
    content: "A. Yes,please contact us with our chat",
  },
  {
    title: "Q. Do you provide Twitter followers?",
    content: "A. Yes,sure",
  },
  {
    title: "Q. Do you provide Youtube views?",
    content: "A. Yes,sure",
  },
];

export { accordionRendering };

export const currentYear = new Date().getFullYear();

export const formatTimestamp = (timestamp) => {
  return timestamp?.replace("T", " ").substring(0, timestamp.lastIndexOf("."));
};

export const generateRandomDots = (count) => {
  return Array.from({ length: count }, (_, index) => ({
    id: index,
    size: `${Math.floor(Math.random() * 9) + 6}px`,
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
  }));
};
