import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const metaData = {
  "/ig_high_quality_fast_likes": {
    title: "Buy High-Quality Instagram Likes Fast | IG Carts",
    description:
      "Boost your Instagram reach with high-quality, fast delivery likes. Get instant results and grow your profile today with IG Carts trusted services.",
  },
  "/instagram_likes": {
    title: "Buy Instagram Likes Instantly | Safe & Reliable Service",
    description:
      "Buy Instagram likes quickly and securely to boost engagement. Trusted by influencers and brands—instant delivery and 100% safe service from IG Carts.",
  },
  "/ig_real_oganic_likes": {
    title: "Buy Real Organic Instagram Likes | IG Carts",
    description:
      "Get authentic Instagram likes from real users. Boost your profile’s credibility and engagement with organic growth solutions at IG Carts.",
  },
  "/ig_auto_high_quality_none_drop_likes": {
    title: "Buy Auto High-Quality Non-Drop Likes | IG Carts",
    description:
      "Automate your Instagram growth with non-drop, high-quality auto likes. Ensure lasting engagement and boost every post effortlessly with IG Carts.",
  },
  "/ig_auto_likes": {
    title: "Buy Auto Instagram Likes | Instant Post Boost",
    description:
      "Automate Instagram likes for every new post. Enjoy instant delivery, boost your reach, and grow your profile with IG Carts' auto-like service.",
  },
  "/ig_auto_real_likes": {
    title: "Buy Auto Real Instagram Likes | IG Carts",
    description:
      "Get real Instagram auto likes for every new post. Boost engagement naturally with authentic likes from real users—trusted service at IG Carts.",
  },
  "/ig_tv_views": {
    title: "Buy IGTV Views for Instagram Videos | IG Carts",
    description:
      "Boost your IGTV videos with high-quality views. Increase reach, improve visibility, and grow your audience fast with IG Carts trusted services.",
  },
  "/ig_auto_views": {
    title: "Buy Auto Instagram Views | Boost Every Video",
    description:
      "Get automatic views on every Instagram video upload. Ensure instant delivery, increase reach, and grow your profile with IG Carts auto views.",
  },
  "/ig_story_views": {
    title: "Buy Instagram Story Views | Real & Fast Delivery",
    description:
      "Increase your Instagram Story reach with real views. Get fast delivery and boost engagement effortlessly with IG Carts story views service.",
  },
  "/ig_lives_view": {
    title: "Buy Instagram Live Views | Boost Real-Time Audience",
    description:
      "Get more viewers on your Instagram Live streams. Boost real-time engagement and build your audience instantly with IG Carts live views service.",
  },
  "/ig_real_usa_followers": {
    title: "Buy Real USA Instagram Followers | IG Carts",
    description:
      "Grow your Instagram with real, active USA followers. Boost credibility, reach, and engagement with fast, reliable delivery from IG Carts.",
  },
  "/ig_real_organic_comments": {
    title: "Buy Real Organic Instagram Comments | IG Carts",
    description:
      "Boost post engagement with genuine, organic comments. Improve visibility and spark real conversations with IG Carts trusted comment service.",
  },
  "/ig_real_organic_usa_story_views": {
    title: "Buy Real USA Story Views | Boost IG Stories",
    description:
      "Get real, organic story views from USA users. Increase your Instagram story reach naturally and improve audience engagement with IG Carts.",
  },
  "/ig_comments_from_verified_accounts": {
    title: "Buy Instagram Comments from Verified Accounts",
    description:
      "Get comments from verified Instagram accounts to boost your profile’s trust and credibility. Secure, fast delivery with IG Carts.",
  },
  "/ig_custom_manually_real_comments": {
    title: "Buy Custom Real Instagram Comments | IG Carts",
    description:
      "Get custom, real comments tailored to your posts. Enhance engagement and social proof with manually written comments from IG Carts.",
  },
  "/ig_auto_real_comments": {
    title: "Buy Auto Real Instagram Comments | IG Carts",
    description:
      "Automate your Instagram engagement with real comments on every post. Boost interactions effortlessly with IG Carts auto comments service.",
  },
  "/ig_auto_daily_real_usa_followers": {
    title: "Buy Auto Daily Real USA Followers | IG Carts",
    description:
      "Gain real USA followers daily with auto delivery. Grow your Instagram organically and steadily with IG Carts reliable follower service.",
  },
  "/ig_followers": {
    title: "Buy Instagram Followers | Fast & Real Growth",
    description:
      "Boost your Instagram profile with real followers. Safe, fast delivery to improve your reach and grow your audience with IG Carts.",
  },
  "/ig_real_engagements": {
    title: "Buy Real Instagram Engagement | IG Carts",
    description:
      "Increase likes, comments, and views with real Instagram engagement. Boost your profile’s performance naturally with IG Carts trusted service.",
  },
  "/ig_auto_real_engagements": {
    title: "Buy Auto Real Instagram Engagement | IG Carts",
    description:
      "Automate likes, comments, and views with real engagement for every post. Boost performance consistently with IG Carts auto engagement service.",
  },
  "/auto_ig_real_comments_and_likes": {
    title: "Buy Auto Instagram Comments & Likes | IG Carts",
    description:
      "Automate real likes and comments on every post. Boost engagement and grow your Instagram naturally with IG Carts auto services.",
  },
  "/ig_impressions": {
    title: "Buy Instagram Impressions | Boost Post Visibility",
    description:
      "Increase your Instagram impressions to enhance post reach and profile visibility. Trusted service with fast delivery from IG Carts.",
  },
  "/instagram_mentions": {
    title: "Buy Instagram Mentions | Expand Your Reach",
    description:
      "Get your profile mentioned across Instagram to increase visibility and grow your audience. Fast, safe, and effective service by IG Carts.",
  },
  "/blogs": {
    title: "Expert Insights and Tips",
    description:
      "Expert advice, industry trends, latest updates and tips to boost your social media engagement",
  },
};

const SEO = () => {
  const location = useLocation();
  const canonicalUrl = `https://igcarts.com${location.pathname}`;

  const { title, description } = metaData[location.pathname] || {
    title: "Igcart - Buy Instagram, TikTok, Facebook followers, likes and more",
    description:
      "Go viral with Ease. We offer the best prices to boost your Instagram, TikTok, Facebook, X, Spotify, Soundcloud and YouTube with real organic followers, Likes, and Views!",
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default SEO;
