import React from "react";
import classNames from "classnames";

const Button = ({ title, size = "sm", variant = "primary", ...props }) => (
  <button
    className={classNames(
      "inline-flex items-center justify-center w-auto font-medium rounded-md shadow focus:outline-none disabled:opacity-50",
      {
        "py-1 px-2 text-base font-light": size === "xs",
        "py-2 px-6 text-base font-normal": size === "sm",
        "py-2 px-6 text-base font-bold": size === "md",
        "bg-[#f49a39] text-white": variant === "primary",
        "bg-[#001f68] text-white": variant === "secondary",
        "bg-gray-500 text-white": variant === "disabled",
      }
    )}
    {...props}
  >
    {title}
  </button>
);

export default Button;
