import React, { useEffect, useState } from "react";
import Table from "../../../../components/shared/Table";
import Button from "../../../../components/shared/Button";
import { Modal } from "antd";
import responseMessages from "../../../../utils/responseMessages";
import IpCheck from "./components/IpCheck";
import { ApiService } from "../../../../services/apiService";

const IpSettings = () => {
  const [ipAddressData, setIpAddressData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedIpId, setSelectedIpId] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [ipAddressPost, setIpAddressPost] = useState({
    ip_address: "",
    username: "",
    port: "",
    password: "",
  });
  const [editedIpPost, setEditedIpPost] = useState({
    ip_id: null,
    ip_address: "",
    username: "",
    port: "",
    password: "",
  });

  // Fetch all available auto subscriptions
  const fetchIpAddresses = async () => {
    try {
      const response = await ApiService.getAllIPs();
      setIpAddressData(response.data.data);
      console.log("ip response", response.data.data);
    } catch (error) {
      alert(
        "We encountered an error while fetching services, please try again"
      );
    }
  };

  // Load the fetchIpSettings() call on component mount
  useEffect(() => {
    fetchIpAddresses();
  }, []);

  // deleting post functionality starts
  const showDeleteModal = (ipId) => {
    setSelectedIpId(ipId);
    setDeleteModalVisible(true);
  };
  const hideDeleteModal = () => {
    setDeleteModalVisible(false);
  };
  const handleRemove = (row) => {
    showDeleteModal(row.ip_id);
  };
  const deleteIpConfirmation = async () => {
    try {
      const response = await ApiService.deleteIP(selectedIpId);
      if (response.status === 200) {
        setIpAddressData((prevData) =>
          prevData.filter((ip) => ip.ip_id !== selectedIpId)
        );
        hideDeleteModal();
      } else {
        alert("Failed to delete service. Please try again later.");
      }
    } catch (error) {
      alert("Error deleting service, please try again later.");
      setDeleteModalVisible(false);
    }
  };
  // deleting post functionality ends

  const IpAddressDetails = [
    { name: "Ip Address", selector: (row) => row.ip_address },
    { name: "Port", selector: (row) => row.port },
    { name: "Username", selector: (row) => row.username },
    { name: "Password", selector: (row) => row.password },
    {
      name: "Edit",
      selector: (row) => (
        <button onClick={() => handleEdit(row.ip_id)} className="text-blue-700">
          Edit
        </button>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <button
          onClick={() => handleRemove(row)}
          className="text-red-700 italic"
        >
          Delete
        </button>
      ),
      width: "90px",
    },
  ];

  // posting functionality starts
  // for the input fields changing
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIpAddressPost({ ...ipAddressPost, [name]: value });
  };
  // Add auto subscription service
  const addIpAddresses = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await ApiService.createIP(ipAddressPost);

      setIpAddressData([...ipAddressData, response.data]);
      setLoading(false);
      fetchIpAddresses();
      setIpAddressPost({
        ip_address: "",
        username: "",
        port: "",
        password: "",
      });
    } catch (error) {
      alert("Error adding service, check your connection and try again");
      setLoading(false);
    }
  };
  // posting functionality ends

  // editing functionality starts
  const handleEdit = (ipId) => {
    const selectedIp = ipAddressData.find((ip) => ip.ip_id === ipId);
    if (selectedIp) {
      setEditedIpPost({
        ip_id: selectedIp.ip_id,
        ip_address: selectedIp.ip_address,
        port: selectedIp.port,
        password: selectedIp.password,
        username: selectedIp.username,
      });
      setEditModalVisible(true);
    } else {
      console.error("Ip not found for editing.");
    }
  };
  const onEditChange = (e) => {
    setEditedIpPost((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { ip_address, username, port, password } = editedIpPost;
      const response = await ApiService.editIP(editedIpPost.ip_id, {
        ip_address,
        username,
        port,
        password,
      });

      setLoading(false);
      if (response.status === 200) {
        alert("Ip details updated successfully.");
        setEditModalVisible(false);
        fetchIpAddresses();
      } else {
        alert("Failed to update ip details. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      alert(responseMessages.errorMessage);
    }
  };
  const closeModal = () => {
    setEditModalVisible(false);
    setEditedIpPost({ ip_address: "", username: "", port: "", password: "" });
  };
  // editing functionality ends
  return (
    <div className="w-full">
      {/* ip get display table  */}
      <Table
        title="Ip Addresses"
        column={IpAddressDetails}
        data={ipAddressData}
      />
      {/* ip post form */}
      <form onSubmit={addIpAddresses} className="mt-5">
        <div className="flex flex-wrap justify-center gap-4 mb-5 sm:flex-col xs:flex-col md:flex-wrap lg:flex-wrap sm:items-center xs:items-center md:items-center lg:items-center">
          <input
            type="text"
            name="ip_address"
            placeholder="Ip address"
            value={ipAddressPost.ip_address}
            onChange={handleInputChange}
            required
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
          />
          <input
            type="text"
            name="port"
            placeholder="Port"
            value={ipAddressPost.port}
            onChange={handleInputChange}
            required
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
          />
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={ipAddressPost.username}
            onChange={handleInputChange}
            required
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
          />
          <input
            type="text"
            name="password"
            placeholder="Password"
            value={ipAddressPost.password}
            onChange={handleInputChange}
            required
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
          />
        </div>
        <div className="flex justify-center mt-6">
          <Button
            type="submit"
            title={loading ? "Creating Ip..." : "Create Ip"}
            size="md"
            disabled={loading}
          />
        </div>
      </form>

      {/* Edit Modal */}
      <div
        className={`fixed inset-0 flex items-center justify-center bg-white bg-opacity-95 ${
          editModalVisible ? "block" : "hidden"
        }`}
      >
        <div className="w-full xl:px-12 lg:px-12 px-6">
          <span
            className="font-semibold text-3xl cursor-pointer absolute right-14"
            onClick={closeModal}
          >
            &times;
          </span>
          <h2 className="font-semibold text-xl text-center">Edit Blog Post</h2>
          <form
            onSubmit={handleEditSubmit}
            className="flex flex-wrap justify-center gap-4 mb-5 sm:flex-col xs:flex-col md:flex-wrap lg:flex-wrap sm:items-center xs:items-center md:items-center lg:items-center"
          >
            <div className="flex flex-col gap-1">
              <label className="font-FiraSans text-lg"></label>
              <input
                type="text"
                placeholder="Put the Ip address here..."
                value={editedIpPost.ip_address}
                onChange={onEditChange}
                name="ip_address"
                required
                className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="font-FiraSans text-lg"></label>
              <input
                placeholder="Type in your port number..."
                value={editedIpPost.port}
                onChange={onEditChange}
                name="port"
                required
                className="py-2 px-2 font-FiraSans resize-none outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="font-FiraSans text-lg"></label>
              <input
                placeholder="Type in your username here..."
                value={editedIpPost.username}
                onChange={onEditChange}
                name="username"
                required
                className="py-2 px-2 font-FiraSans resize-none outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="font-FiraSans text-lg"></label>
              <input
                placeholder="Type in your password here..."
                value={editedIpPost.password}
                onChange={onEditChange}
                name="password"
                required
                className="py-2 px-2 font-FiraSans resize-none outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
              />
            </div>
            <div className="flex justify-center">
              <Button
                type="submit"
                title={loading ? "Updating Ipdetails..." : "Update Ipdetails"}
                size="md"
                disabled={loading}
              />
            </div>
          </form>
        </div>
      </div>
      {/* Delete user Confirmation Modal */}
      <Modal
        title={<span style={{ color: "#f44336" }}>Confirm Deletion</span>}
        open={deleteModalVisible}
        onOk={deleteIpConfirmation}
        onCancel={hideDeleteModal}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{
          style: { backgroundColor: "#f44336", color: "#ffffff" },
        }}
        cancelButtonProps={{
          style: { backgroundColor: "#fc8a24", color: "#ffffff" },
        }}
      >
        <p className="font-semibold">
          Are you sure you want to delete this Ip Address?
        </p>
      </Modal>

      <IpCheck />
    </div>
  );
};

export default IpSettings;
