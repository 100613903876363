import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Fallback from "./components/shared/Fallback";
import Homepage from "./views/Homepage";
import ThreadsFollowers from "./views/Threads/thread_followers";
import ThreadsLikes from "./views/Threads/thread_likes";
import ThreadsComments from "./views/Threads/thread_comment";
import FbPostLikes from "./views/Facebook/FbPostLikes";
import FbPageLikes from "./views/Facebook/FbPageLikes";
import FbVideoViews from "./views/Facebook/FbVideoViews";
import IgCommentsFromFollowers from "./views/Engagements/IgCommentsFromFollowers";
import IgLikesFromFollowers from "./views/Engagements/IgLikesFromFollowers";
import YoutubeLikes from "./views/Youtube/YoutubeLikes";
import YoutubeSubscribers from "./views/Youtube/YoutubeSubscribers";
import YoutubeViews from "./views/Youtube/YoutubeViews";
import SoundCloudLikes from "./views/SoundCloud/SoundCloudLikes";
import SoundCloudPlay from "./views/SoundCloud/SoundCloudPlay";
import TiktokComment from "./views/Tiktok/TiktokComment";
import TikTokViews from "./views/Tiktok/TikTokViews";
import TikTokLikes from "./views/Tiktok/TiktokLikes";
import TikTokFollowers from "./views/Tiktok/TitktokFollowers";
import TiktokLinkViews from "./views/Tiktok/TiktokLinkViews";
import TiktokLinkLikes from "./views/Tiktok/TiktokLinkLikes";
import IgReelsViews from "./views/IgReels/IgReelsViews";
import IgReelsLikes from "./views/IgReels/IgReelsLikes";
import IgReelsOrganicLikes from "./views/IgReels/IgReelsOrganicLikes";
import IgReelsOrganicComments from "./views/IgReels/IgReelsOrganicComments";
import IgReelsViewsLink from "./views/IgReels/IgReelsViewsLink";
import IgReelsLikesLinks from "./views/IgReels/IgReelsLikesLinks";
import HighQualityLikes from "./views/IgLikes/HighQualityLikes";
import InstagramLikes from "./views/IgLikes/InstagramLikes";
import InstagramRealOrganicLikes from "./views/IgLikes/InstagramRealOrganicLikes";
import AutoHighQualityLikes from "./views/IgLikes/AutoHighQualityLikes";
import AutoLikes from "./views/IgLikes/AutoLikes";
import AutoRealLikes from "./views/IgLikes/AutoRealLikes";
import InstagramViews from "./views/IgViews/InstagramViews";
import IgtvViews from "./views/IgViews/IgtvViews";
import AutoViews from "./views/IgViews/AutoViews";
import AutoViewsPlusImpression from "./views/IgViews/AutoViewsPlusImpression";
import InstagramViewsPlusImpression from "./views/IgViews/InstagramViewsPlusImpression";
import StoryView from "./views/IgViews/StoryView";
import IgLiveViews from "./views/IgViews/IgLiveViews";
import RealUSAFollowers from "./views/Instagram/RealUSAFollowers";
import InstaRealComments from "./views/Instagram/InstaRealComments";
import RealUSAStoryViews from "./views/Instagram/RealUSAStoryViews";
import CommentFromVerifiedAcct from "./views/Instagram/CommentFromVerifiedAcct";
import CustomManualRealComm from "./views/Instagram/CustomManualRealComm";
import AutoRealComment from "./views/Instagram/AutoRealComment";
import AutoDailyUSAFollowers from "./views/Instagram/AutoDailyUSAFollowers";
import Followers from "./views/Instagram/Followers";
import AutoDailyFollowers from "./views/Instagram/AutoDailyFollowers";
import RealEngagement from "./views/Instagram/RealEngagement";
import AutoRealEngagement from "./views/Instagram/AutoRealEngagement";
import AutoRealPackage from "./views/Instagram/AutoRealPackage";
import InstagramImpressions from "./views/Instagram/InstagramImpressions";
import Mention from "./views/Instagram/Mention";
import IgSaves from "./views/Instagram/IgSaves";
import ProfileVisit from "./views/Instagram/ProfileVisit";
import Signin from "./views/Signin";
import Signup from "./views/Signup";
import Faqs from "./views/Faqs";
import ForgetPassword from "./views/ForgetPassword";
import NewPassword from "./views/NewPassword";
import Blog from "./views/Blog";
import Services from "./views/Services";
import Dashboard from "./views/Dashboard";
import StripeSuccess from "./views/Dashboard/components/StripeSuccess";
import StripeCancel from "./views/Dashboard/components/StripeCancel";
import ConfigandSett from "./views/Admin/components/ConfigandSett";
import OrderEmailAndCoupon from "./views/Admin/components/OrderEmailAndCoupon";
import TwitterFollowers from "./views/Twitter/TwitterFollowers";
import AllUsers from "./views/Admin/components/AllUsers";
import BalanceLayout from "./components/BalanceLayout";
import PackageBalanceLayout from "./components/packageBalanceLayout";
import UsersOrders from "./views/Admin/components/UsersOrders";
import ConfirmPayment from "./views/Dashboard/components/ConfirmPayment";
import AdminLogin from "./views/Admin/components/Auth/AdminLogin";
import SpotifyPlays from "./views/Spotify/SpotifyPlays";
import SpotifyFollowers from "./views/Spotify/SpotifyFollowers";
import SpotifyPlaylist from "./views/Spotify/SpotifyPlaylist";
import AdminForgetPassword from "./views/Admin/components/Auth/AdminForgetPassword";
import AdminNewPassword from "./views/Admin/components/Auth/AdminNewPassword";
import AdminPage from "./views/Admin";
import Tickets from "./views/Tickets";
import SessionTimeoutNotifier from "./utils/tokenExpiration";
import AdminBlog from "./views/Admin/components/AdminBlog";
import AIGrowth from "./views/AIGrowthPackage/AIGrowth";
import AdminSessionTimeoutNotifier from "./utils/adminTokenExpiration";
import { AccordionProvider } from "./SEO/AccordionContext";
import AutoServices from "./views/Admin/components/AutoServices";
import IpSettings from "./views/Admin/components/IpSettings";
import TwitterLikes from "./views/Twitter/TwitterLikes";
import TwitterViews from "./views/Twitter/TwitterViews";
import TwitterRetweet from "./views/Twitter/TwitterRetweet";
import NoticeArea from "./views/Admin/components/NoticeArea";
import BlogPost from "./views/Blog/Blogpost";
import Aboutus from "./views/Aboutus";
import Contactus from "./views/Contactus";
import PrivacyPolicy from "./views/Privacy";
import TermsOfService from "./views/Terms";
import { HelmetProvider } from "react-helmet-async";
import SEO from "./SEO/SEO";
import CampaignLogin from "./views/campaign/LoginCam";
import ChangePasswordCam from "./views/campaign/ChangePasswordCam";
import { ToastContainer } from "react-toastify";

function App() {
  const token = localStorage.getItem("token");
  const adminToken = localStorage.getItem("adminToken");

  return (
    <HelmetProvider>
      <BrowserRouter>
        {token && <SessionTimeoutNotifier />}
        {adminToken && <AdminSessionTimeoutNotifier />}
        <SEO />
        <ToastContainer />
        <AccordionProvider>
          <Routes>
            <Route path="/admin" element={<AdminPage />}>
              <Route
                path="configuration-and-settings"
                element={<ConfigandSett />}
              />
              <Route
                path="order-email-and-coupon-settings"
                element={<OrderEmailAndCoupon />}
              />
              <Route path="user-details" element={<AllUsers />} />
              <Route path="all-users-orders" element={<UsersOrders />} />
              <Route path="blog-post-posting" element={<AdminBlog />} />{" "}
              <Route path="auto-services" element={<AutoServices />} />
              <Route path="ip_configuration" element={<IpSettings />} />
              <Route path="notice_area" element={<NoticeArea />} />
            </Route>
            <Route path="/admin_signin" element={<AdminLogin />} />
            <Route
              path="/admin_forget_password"
              element={<AdminForgetPassword />}
            />
            <Route
              path="/admin_reset_password"
              element={<AdminNewPassword />}
            />
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin_c" element={<CampaignLogin />} />
            <Route path="/change-password_c" element={<ChangePasswordCam />} />
            <Route path="*" element={<Fallback />} />
            <Route element={<Layout />}>
              {token && token ? (
                <Route path="/" element={<Dashboard />} />
              ) : (
                <Route path="/" element={<Homepage />} />
              )}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/" element={<Homepage />} />
              <Route
                path="/dashboard/payment-success"
                element={<StripeSuccess />}
              />
              <Route
                path="/dashboard/confirm-payment"
                element={<ConfirmPayment />}
              />
              <Route
                path="/dashboard/payment-error"
                element={<StripeCancel />}
              />
              <Route path="/services" element={<Services />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/forget_password" element={<ForgetPassword />} />
              <Route path="/new_password" element={<NewPassword />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/blog/:heading" element={<BlogPost />} />
              <Route path="/complain_ticket" element={<Tickets />} />
              <Route path="/about_us" element={<Aboutus />} />
              <Route path="/contact_us" element={<Contactus />} />
              <Route path="/privacy_policy" element={<PrivacyPolicy />} />
              <Route path="/terms_of_service" element={<TermsOfService />} />
              <Route element={<PackageBalanceLayout />}>
                <Route path="/ig_ai_account_growth" element={<AIGrowth />} />
                <Route path="/thread_comment" element={<ThreadsComments />} />
                <Route
                  path="/ig_real_organic_comments"
                  element={<InstaRealComments />}
                />
                <Route
                  path="/ig_real_engagements"
                  element={<RealEngagement />}
                />
                <Route
                  path="/ig_auto_real_engagements"
                  element={<AutoRealEngagement />}
                />
                <Route
                  path="/ig_real_Comments_from_your_followers"
                  element={<IgCommentsFromFollowers />}
                />
                <Route
                  path="/ig_comments_from_verified_accounts"
                  element={<CommentFromVerifiedAcct />}
                />
                <Route
                  path="/ig_custom_manually_real_comments"
                  element={<CustomManualRealComm />}
                />
                <Route path="/tiktok_comments" element={<TiktokComment />} />
                <Route
                  path="/ig_auto_real_comments"
                  element={<AutoRealComment />}
                />
                <Route
                  path="/ig_reels_real_organic_comments"
                  element={<IgReelsOrganicComments />}
                />
                <Route path="/youtube_views" element={<YoutubeViews />} />
                <Route path="/soundcloud_play" element={<SoundCloudPlay />} />
                <Route path="/spotify_plays" element={<SpotifyPlays />} />
              </Route>
              <Route element={<BalanceLayout />}>
                <Route
                  path="/ig_real_Likes_from_your_followers"
                  element={<IgLikesFromFollowers />}
                />
                {/*Tiktok route starts*/}
                <Route path="/tiktok_views" element={<TikTokViews />} />
                <Route path="/tiktok_likes" element={<TikTokLikes />} />
                <Route path="/tiktok_followers" element={<TikTokFollowers />} />
                <Route
                  path="/tiktok_views-link"
                  element={<TiktokLinkViews />}
                />
                <Route
                  path="/tiktok_likes-link"
                  element={<TiktokLinkLikes />}
                />
                {/*Tiktok route ends*/}
                {/*IgReels route start*/}
                <Route path="/ig_reels_views" element={<IgReelsViews />} />
                <Route path="/ig_reels_likes" element={<IgReelsLikes />} />
                <Route
                  path="/ig_reels_real_organic_likes"
                  element={<IgReelsOrganicLikes />}
                />

                <Route
                  path="/ig_reels_views-link"
                  element={<IgReelsViewsLink />}
                />
                <Route
                  path="/ig_reels_likes-link"
                  element={<IgReelsLikesLinks />}
                />
                {/*IgReels route ends*/}
                {/*IgLikes routes start*/}
                <Route
                  path="/ig_high_quality_fast_likes"
                  element={<HighQualityLikes />}
                />
                <Route path="/instagram_likes" element={<InstagramLikes />} />
                <Route
                  path="/ig_real_oganic_likes"
                  element={<InstagramRealOrganicLikes />}
                />
                <Route
                  path="/ig_auto_high_quality_none_drop_likes"
                  element={<AutoHighQualityLikes />}
                />
                <Route path="/ig_auto_likes" element={<AutoLikes />} />
                <Route path="/ig_auto_real_likes" element={<AutoRealLikes />} />
                {/*IgLikes routes ends*/}
                {/*IgViews routes start*/}
                <Route path="/instagram_views" element={<InstagramViews />} />
                <Route path="/ig-tv_views" element={<IgtvViews />} />
                <Route path="/ig_auto_views" element={<AutoViews />} />
                <Route
                  path="/ig_auto_views_plus_impression"
                  element={<AutoViewsPlusImpression />}
                />
                <Route
                  path="/ig_views_plus_impressions"
                  element={<InstagramViewsPlusImpression />}
                />
                <Route path="/ig_story_views" element={<StoryView />} />
                <Route path="/ig_lives_view" element={<IgLiveViews />} />
                {/*IgViews routes ends*/}
                {/*Instagram routes start*/}
                <Route
                  path="/ig_real_usa_followers"
                  element={<RealUSAFollowers />}
                />

                <Route
                  path="/ig_real_organic_usa_story_views"
                  element={<RealUSAStoryViews />}
                />

                <Route
                  path="/ig_auto_daily_real_usa_followers"
                  element={<AutoDailyUSAFollowers />}
                />
                <Route path="/ig_followers" element={<Followers />} />
                <Route
                  path="/ig_auto_daily_followers"
                  element={<AutoDailyFollowers />}
                />

                <Route
                  path="/auto_ig_real_comments_and_likes"
                  element={<AutoRealPackage />}
                />
                <Route
                  path="/ig_impressions"
                  element={<InstagramImpressions />}
                />
                <Route path="/instagram_mentions" element={<Mention />} />
                <Route path="/ig_saves" element={<IgSaves />} />
                <Route path="/ig_profile_visits" element={<ProfileVisit />} />
                {/*Instagram routes end*/}
                <Route
                  path="/thread_followers"
                  element={<ThreadsFollowers />}
                />
                <Route path="/thread_likes" element={<ThreadsLikes />} />
                <Route
                  path="/thread_followers"
                  element={<ThreadsFollowers />}
                />
                <Route path="/thread_likes" element={<ThreadsLikes />} />
                <Route path="/facebook_post_likes" element={<FbPostLikes />} />
                <Route path="/facebook_page_likes" element={<FbPageLikes />} />
                <Route
                  path="/facebook_video_views"
                  element={<FbVideoViews />}
                />
                <Route path="/youtube_likes" element={<YoutubeLikes />} />
                <Route
                  path="/youtube_subscribers"
                  element={<YoutubeSubscribers />}
                />
                <Route path="/soundcloud_likes" element={<SoundCloudLikes />} />
                <Route
                  path="/spotify_followers"
                  element={<SpotifyFollowers />}
                />
                <Route
                  path="/spotify_playlist_followers"
                  element={<SpotifyPlaylist />}
                />
                <Route
                  path="/twitter_followers"
                  element={<TwitterFollowers />}
                />
                <Route path="/twitter_likes" element={<TwitterLikes />} />
                <Route path="/twitter_views" element={<TwitterViews />} />
                <Route path="/twitter_retweet" element={<TwitterRetweet />} />
                {/*all routes with navbar, footer and balance component goes in here*/}
              </Route>
              {/*all routes with navbar and footer goes in here*/}
            </Route>
          </Routes>
        </AccordionProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
