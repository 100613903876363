import adminApi from "./adminAuthInterceptor";
import api from "./authInterceptor";

export const ApiService = {
  sendContactMessage: async (data) => {
    try {
      const response = api.post("/contact-us", data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getAllBlogs: async () => {
    try {
      const response = api.get("/blog/get-blog");

      return response;
    } catch (error) {
      throw error;
    }
  },
  getOneBlog: async (blog_id) => {
    try {
      const response = api.get(`/blog/get/${blog_id}`);

      return response;
    } catch (error) {
      throw error;
    }
  },

  dashboardNotice: async () => {
    try {
      const response = api.get("/notice/get");
      return response;
    } catch (error) {
      throw error;
    }
  },
  adminAllOrders: async () => {
    try {
      const response = adminApi.get("/social/get-all-orders");

      return response;
    } catch (error) {
      throw error;
    }
  },
  // admin blogs apis start
  createBlog: async (data) => {
    try {
      const response = adminApi.post("/blog/create-blog", data);

      return response;
    } catch (error) {
      throw error;
    }
  },
  editBlog: async (blog_id, data) => {
    try {
      const response = adminApi.patch(`/blog/update-blog/${blog_id}`, data);

      return response;
    } catch (error) {
      throw error;
    }
  },
  adminBlogs: async () => {
    try {
      const response = adminApi.get("/blog/get-adminBlogs");

      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteABlog: async (selectedBlogId) => {
    try {
      const response = adminApi.delete(`/blog/delete-blog/${selectedBlogId}`);

      return response;
    } catch (error) {
      throw error;
    }
  },
  // admin blog apis ends
  adminindividualUserOrders: async () => {
    try {
      const response = adminApi.get("/user/get-user-with-order");
      return response;
    } catch (error) {
      throw error;
    }
  },

  getAllUsers: async () => {
    try {
      const response = adminApi.get("/user/all-users");
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteAUser: async (data) => {
    try {
      const response = adminApi.patch("/user/delete-user", data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  editAUser: async (data) => {
    try {
      const response = adminApi.patch("/user/update-user", data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  // admin campaign
  campaignCSV: async (formData) => {
    try {
      const response = adminApi.post("/campaign/upload", formData);
      return response;
    } catch (error) {
      throw error;
    }
  },
  // user campaign
  userCampaignLogin: async (data) => {
    try {
      const response = api.post("/campaign/change", data);

      return response;
    } catch (error) {
      throw error;
    }
  },
  // Ips
  getIPMakingPost: async () => {
    try {
      const response = adminApi.get("/auto-order");
      return response;
    } catch (error) {
      throw error;
    }
  },
  getAllIPs: async () => {
    try {
      const response = adminApi.get("/ip/get");
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteIP: async (selectedIPId) => {
    try {
      const response = adminApi.delete(`/ip/delete/${selectedIPId}`);

      return response;
    } catch (error) {
      throw error;
    }
  },
  createIP: async (data) => {
    try {
      const response = adminApi.post("/ip/create", data);

      return response;
    } catch (error) {
      throw error;
    }
  },
  editIP: async (ip_id, data) => {
    try {
      const response = adminApi.patch(`/ip/update/${ip_id}`, data);

      return response;
    } catch (error) {
      throw error;
    }
  },
  // email and coupon
  getCurrentEmailAndCoupon: async () => {
    try {
      const response = adminApi.get("/admin/get-email");
      return response;
    } catch (error) {
      throw error;
    }
  },
  editEmailAndCoupon: async (data) => {
    try {
      const response = adminApi.patch("admin/change-email", data);

      return response;
    } catch (error) {
      throw error;
    }
  },
};
