import React, { useState } from "react";
import Button from "../../../../components/shared/Button";
import { ApiService } from "../../../../services/apiService";

const Campaign = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("csvFile", file);

    try {
      const response = await ApiService.campaignCSV(formData);

      if (response.status === 200 || response.status === 201) {
        alert("File uploaded successfully!");
      }
    } catch (error) {
      console.error("Upload error:", error);
      alert("Error uploading file. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-lg text-center font-semibold mb-4">
        Upload Campaign CSV File
      </h2>
      <div className="flex gap-4 sm:flex-col xs:flex-col items-center">
        <label className="border p-2 rounded-md">
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className=""
          />
        </label>
        <Button
          title={uploading ? "Uploading..." : "Upload"}
          variant={!uploading ? "primary" : "disabled"}
          onClick={handleUpload}
          disabled={uploading}
        />
      </div>
    </div>
  );
};

export default Campaign;
